import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// context 
import DataProvider from './context/DataProvider/DataProvider';

import BusinessTracker from './pages/home-pages/business-tracker';

import NioScrollToTop from './components/NioScrollToTop/NioScrollToTop';


function AppRoutes() {

  return (
    <Routes>

      {/* landing page */}
      <Route path="/" element={<BusinessTracker />} />
      {/* miscellaneous pages */}
      <Route path="*" element={<BusinessTracker />} />

    </Routes>
  );
}


function App() {

  useEffect(() => {
    document.body.classList.add('nk-body');
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        <NioScrollToTop />
        <AppRoutes />
      </BrowserRouter>
    </DataProvider>
  );
}


export default App;
