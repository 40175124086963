import React from 'react';

export default function NioStickyBadge() {

  return (
    <div className="nk-sticky-badge">
      &nbsp;
    </div>
  )
}
