import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';

// layouts
import AppLayout from '../../../layouts/AppLayout/AppLayout';

// components
import { NioSection, NioMedia, NioIcon, NioButton, NioCount, NioSubscribeField, NioField } from '../../../components';

// section content 
import FaqContent from '../../../components/PageComponents/Homepages/BusinessTracker/FaqContent/FaqContent';
import PricingContent from '../../../components/PageComponents/Homepages/BusinessTracker/PricingContent/PricingContent';
import TestimonialContent from '../../../components/PageComponents/Homepages/BusinessTracker/TestimonialContent/TestimonialContent';
import HeaderAction from '../../../components/Header/components/HeaderAction/HeaderAction';
import config from '../../../data/layout-config';
import { useLayout } from '../../../context/LayoutProvider/LayoutProvider';

function index() {

  const variant = 2;

  function filterDataByVariant(variantNumber) {
    return config.filter(item => item.variant === variantNumber);
  }
  const [variantsNav] = filterDataByVariant(variant || 1);
  
  const actionClasses = classNames({
    [`nk-header-action`]: true,
  });

  const toggleBtnClass = classNames({
    [`btn-outline-${variantsNav?.header.dark ? 'white' : variantsNav?.header.toggleDark ? 'dark' : 'gray-700'}`]: true,
    [`navbar-toggle rounded-1 p-2 h-100 p-2`]: true,
    [`active`]: "true",
  });

  const nioBtnClasses = classNames({
    [`btn-${variantsNav?.header.actBtn.variant.type === 'outline' ?
      `outline-${variantsNav?.header.actBtn.variant.color}` : `${variantsNav?.header.actBtn.variant.color}`}`]: variantsNav?.header.actBtn,
    [`rounded-pill`]: variantsNav?.header.actBtn.rounded,
  });
  return (
    <AppLayout variant={5} title="AutomateItPlease!" rootClass="layout-4">

      {/*   Banner Section Start  */}
      <section className="nk-banner nk-banner-business-expance-tracker bg-primary-gradient-soft">
        <div className="nk-banner-wrap">
          <Container>
            <Row className="flex-row-reverse align-items-center justify-content-between">
              <Col lg={6}>
                <div className="nk-banner-img mb-5  mb-lg-0" >
                  <img src="images/business-tracker/banner-cover.png" alt="banner-cover" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="nk-banner-content">
                  <h1 className="display-6 mb-2">Streamline Ops, Save Millions Of Hours Every Week !</h1>
                  <p className="fs-20 mb-0" >Let's connect and discuss how we can build an AI & Automation ecosystem that can save you a lot of time by streamlining operations!</p>
                <div className="mt-5">
                  <form>
                    <Row className="form-group nk-newsletter-one justify-content-center justify-content-xl-start">
                      <Col md={8}>
                        <NioField.Input className="border-gray-200" childClass="p-3" type="email" placeholder="Enter Your Email" />
                      </Col>
                      <Col md={3} className="ps-md-0">
                        <NioButton type="submit" label="Get More Details" className="btn-primary-alt text-nowrap btn-block h-100 mt-3 mt-md-0" />
                      </Col>
                    </Row>
                  </form>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  Banner Section End   */}

      {/*  Feature Section Start   */}
      <NioSection className="nk-feature-section pb-7 pb-lg-120">
        <NioSection.Head alignX="center">
          <span className="d-inline-block fs-16 text-uppercase text-primary-alt fw-bold mb-2">What We Offer</span>
          <h2>Here is how we help you</h2>
          <p className="fs-20 mb-0"> Automate Repetitive Tasks, Optimize Resources, and Accelerate Growth with Our Intelligent <b>AI Workflow Automation</b> </p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gap g-5">
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">Cut Down on Overhiring</h4>
                  <p className="fs-16 line-clamp-3 fw-medium">Slash staffing costs by replacing manual tasks with AI-powered automation. Efficiency, without the extra payroll.</p>
                </div>
                <NioButton href="/features" className="btn-link text-primary-alt d-inline-block" label="learn More" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-a.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">Reclaim Your Time</h4>
                  <p className="fs-16 line-clamp-3 fw-medium">Ditch repetitive tasks and gain back millions of hours. With advanced AI and optimized workflows, we make it happen.</p>
                </div>
                <NioButton href="/features" className="btn-link text-primary-alt d-inline-block" label="Learn More" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-b.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">Scale Seamlessly</h4>
                  <p className="fs-16 line-clamp-3 fw-medium">Custom automation solutions that grow with you. Eliminate low-value tasks, boost productivity, and watch your business soar.</p>
                </div>
                <NioButton href="/features" className="btn-link text-primary-alt d-inline-block" label="Learn More" icon="arrow-right after" />
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Feature Section End   */}

      {/*  Integrate Section Start  */}
      <NioSection py={false} className="nk-section-integrate bg-orange-50 overflow-hidden pb-7 pb-lg-0 py-lg-0 mt-2">
        <NioSection.Content className="position-relative">
          <Row className="justify-content-between">
            <Col lg={5} className="align-self-center">
              <div className="nk-section-head text-center text-lg-start py-7 py-lg-220">
                <div>
                  <h2 className="h1 mb-2">
                    <span className="title-shape title-shape-3 title-shape-3_two">Effortless</span> Integration
                  </h2>
                  <p className="fs-20">Custom-built automations that effortlessly fit into your existing software, taking over repetitive tasks with ease.</p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="infinite-scroll">
                <div className="infinite-scroll-col infinite-scroll-init infinite-scroll-lg-vertical infinite-scroll-horizontal infinite-scroll-duration-12">
                  {
                    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15","16"]?.map((tool, idx) => (
                      <div className="infinite-scroll-card" key={idx}>
                        <img src={`images/tools/tool-${tool}.png`} alt="tool" />
                      </div>
                    ))
                  }
                </div>
                <div className="infinite-scroll-col infinite-scroll-init infinite-scroll-lg-verticalAlternate infinite-scroll-horizontalAlternate infinite-scroll-duration-12">
                  {
                    ["16","15", "14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"]?.map((tool, idx) => (
                      <div className="infinite-scroll-card" key={idx}>
                        <img src={`images/tools/tool-${tool}.png`} alt="tool" />
                      </div>
                    ))
                  }
                </div>
                <div className="infinite-scroll-col infinite-scroll-init infinite-scroll-lg-vertical infinite-scroll-horizontal infinite-scroll-duration-12">
                  {
                    ["15","14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"]?.map((tool, idx) => (
                      <div className="infinite-scroll-card" key={idx}>
                        <img src={`images/tools/tool-${tool}.png`} alt="tool" />
                      </div>
                    ))
                  }
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Integrate Section End  */}

      {/*  Process Section Start   */}
      <NioSection className="nk-process-section py-7 py-lg-120">
        <Row className="justify-content-center">
          <Col lg={8} xl={6}>
            <div className="nk-section-head pb-7 pb-lg-120 text-center">
              <span className="d-inline-block fs-16 text-uppercase text-primary-alt fw-bold mb-2">our process</span>
              <h2>Streamlining Ops, One Step at a Time</h2>
              <ul className="nk-btn-group justify-content-center pt-5">
                <li>
                  <NioButton href="https://calendly.com/n-automateitplease/30min" className="btn-outline-primary-alt" label="Contact Us" />
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <ul className="nk-schedule d-flex flex-column gap-5 gap-md-7 gap-lg-120 nk-schedule-s1">
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="primary-soft" className="fw-bold">
                    1
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-n2 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-2-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-2.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-3">Discovery <span className="text-primary-alt">Call.</span></h3>
                        <p className="fs-20 text-base"> We start with a quick conversation to get to know your business and pinpoint what needs automating. </p>
                        <ul className="nk-btn-group pt-3 pt-md-5">
                          <li>
                            <NioButton href="https://calendly.com/n-automateitplease/30min" className="btn-outline-primary-alt" icon="arrow-right after" label="Learn More" />
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="warning-soft" className="fw-bold">
                    2
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-3-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-3.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-3">Finding the <span className="text-primary">GOLD</span></h3>
                        <p className="fs-20 text-base"> We dive deep to see how we can streamline your processes. Then, we'll hop on a call to show you all the possibilities and how they'll benefit you. </p>
                        <ul className="nk-btn-group pt-3 pt-md-5">
                          <li>
                            <NioButton href="https://calendly.com/n-automateitplease/30min" className="btn-outline-warning" icon="arrow-right after" label="Learn More" />
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner p-0">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="danger-soft" className="fw-bold">
                    3
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-4-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-4.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-3"><span className="text-danger">Get </span>Roling</h3>
                        <p className="fs-20 text-base"> Once you're on board, we handle the setup from start to finish. We'll integrate the automation solutions seamlessly into your operations, ensuring you save over 100 hours a week right away. </p>
                        <ul className="nk-btn-group pt-3 pt-md-5">
                          <li>
                            <NioButton href="https://calendly.com/n-automateitplease/30min" className="btn-outline-danger" label="Learn More" icon="arrow-right after" />
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
          </ul>
        </NioSection.Content>
      </NioSection>
      {/*  Process Section End   */}

{/*  Testimonial Section Start   */}
<NioSection className="bg-orange-50">
        <Row className="justify-content-center">
          <Col xl={7}>
            <div className="nk-section-head pb-7 text-center">
              <span className="d-inline-block fs-16 text-uppercase text-primary-alt fw-bold mb-2">Client Case Studies</span>
              {/* <h2>Stories From Our Customers</h2> */}
              <p className="fs-20 mb-0">
              Here is how we have helped our clients and how our automation solutions have transformed their businesses.
              </p>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <TestimonialContent />
        </NioSection.Content>
        <ul className="nk-btn-group justify-content-center pt-5">
                <li>
                  <NioButton href="https://calendly.com/n-automateitplease/30min" className="btn-primary-alt" label="Be one of them" />
                </li>
              </ul>
      </NioSection>
      {/*  Testimonial Section End   */}

     {/*  Pricing Plans Section Start   */}
      {/* <NioSection className="nk-section-pricing overflow-hidden pt-7 pb-7 pb-lg-120">
        <div className="calendly-inline-widget" data-url="https://calendly.com/magically-genius/automation-as-a-service" style={{minWidth:320,height:800}}></div>
      </NioSection> */}
      {/*  Pricing Plans Section End   */}


      {/*  Faq Section Start   */}
      <NioSection className="pt-7 pt-lg-120">
        <Row className="justify-content-center">
          <Col xl={7}>
            <div className="nk-section-head pb-7 text-center">
              <span className="d-inline-block fs-16 text-uppercase text-primary-alt fw-bold mb-2">faqs</span>
              <div>
                <h2>Frequently Asked Questions</h2>
                <p className="fs-20 mb-0">
                  Find answers to commonly asked questions about our product or service in our comprehensive FAQ section.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <Row className="gy-5 gy-lg-0">
            <Col lg={8}>
              <FaqContent />
            </Col>
            <Col lg={4} >
              <div className="nk-contact-box bg-gray rounded-2 border border-gray-500">
                <div className="nk-contact-content">
                  <div className="media media-lg media-middle media-circle media-border text-primary-alt border-primary mb-3 mb-md-5">
                    <NioIcon name="chat-fill" />
                  </div>
                  <h5 className="title m-0 mb-2">Got more question??</h5>
                  <p className="fs-18 mb-3">Let's discuss those on a quick discovery call..</p>
                  <NioButton href={"https://calendly.com/n-automateitplease/30min"} label="Book a call!" className="btn-outline-primary text-nowrap btn-block h-100 mt-5 mt-md-0" />
                </div>
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Faq Section End   */}
    </AppLayout >
  )
}

export default index;